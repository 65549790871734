<template>
  <div class="row">
    <div class="card p-lg-10 p-sm-5">
      <div>
        <h2 class="text-blue">Editar informe</h2>
      </div>
      <div>
        <form @submit="editar">
          <div class="row">
            <!-- <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="" style="color: #b5b5b5"
                  >Añada uno o mas sensores</label
                >
                <select
                  class="form-select mt-2 rounded-0 border-0"
                  aria-label="Default select example"
                  style="background-color: #f4f8fb"
                  id="ubicacion"
                  v-model="ubicacion"
                >
                  <option style="color: #b5b5b5" selected>
                    Seleccione una ubicación
                  </option>
                  <option
                    v-for="(sede, j) in sedes"
                    :key="j"
                    :value="sede.areaId"
                  >
                    {{ sede.nombre }}
                  </option>
                </select>
              </div>
            </div> -->
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <label for="" style="color: #b5b5b5"
                  >Añada uno o mas equipos</label
                >
                <select
                  class="form-select mt-2 rounded-0 border-0"
                  aria-label="Default select example"
                  style="background-color: #f4f8fb; color: #b5b5b5"
                  v-model="equipoSeleccionado"
                >
                  <option disabled value>Seleccione uno o mas equipos</option>
                  <option
                    v-for="(sensor, i) in sensores"
                    :key="i"
                    :value="sensor"
                  >
                    {{ sensor.nombre }}
                  </option>
                </select>
              </div>
            </div>
            <div class="w-100">
              <button
                @click="selectSensor"
                class="btn bg-blue px-4 py-2 rounded-pill text-white my-3"
                type="button"
              >
                Añadir
              </button>
              <div v-for="(seleccion, k) in equiposSeleccionados" :key="k">
                <div
                  class="alert alert-primary p-2"
                  style="width: max-content"
                  role="alert"
                >
                  {{ seleccion.nombre }}
                  <button
                    class="btn p-0 m-0"
                    @click="borrarEquipoSeleccionado(k)"
                  >
                    <i class="bi bi-x ms-3"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="w-100 my-3">
              <div class="form-group">
                <label style="color: #b5b5b5" for=""
                  >Ingrese nombre del informe</label
                >
                <input
                  type="text"
                  class="form-control rounded-0 border-0 mt-2"
                  style="background-color: #f4f8fb"
                  placeholder="Nombre del informe"
                  v-model="model.nombre"
                  id="nombre"
                />
              </div>
            </div>
            <div class="w-100 my-3">
              <div class="form-group">
                <label style="color: #b5b5b5" for="">Seleccione una hora</label>
                <div class="d-flex align-items-center">
                  <input
                    type="time"
                    class="form-control rounded-0 border-0 mt-2 w-auto"
                    style="background-color: #f4f8fb"
                    v-model="model.hora"
                    id="hora"
                  />
                  <!-- <select
                    class="form-select mt-2 rounded-0 border-0 w-auto mx-2"
                    aria-label="Default select example"
                    style="background-color: #f4f8fb"
                  >
                    <option value="1">AM</option>
                    <option value="1">PM</option>
                  </select> -->
                </div>
              </div>
            </div>
            <div class="w-100">
              <div class="form-group">
                <label style="color: #b5b5b5" for="">Seleccionar días</label>
                <div class="d-flex">
                  <div
                    class="d-flex my-4"
                    v-for="(dia, l) in diasDeLaSemana"
                    :key="l"
                  >
                    <button
                      class="text-white btn p-0 me-2"
                      @click="selectDias(dia.id)"
                      type="button"
                    >
                      <div
                        class="fondoInactivo d-flex align-items-center justify-content-center rounded-circle"
                        style="width: 30px; height: 30px"
                        id="fondoBoton"
                        :class="{
                          fondoActivo: diasSeleccionados.some(
                            (a) => a == dia.id
                          ),
                        }"
                      >
                        {{ dia.nombre }}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 my-3">
              <div class="form-group">
                <label style="color: #b5b5b5" for="">Ingrese email</label>
                <input
                  type="email"
                  class="form-control rounded-0 border-0 mt-2"
                  style="background-color: #f4f8fb"
                  placeholder="Email"
                  v-model="email"
                  id="email"
                />
              </div>
            </div>
            <div class="w-100">
              <button
                type="button"
                @click="onEmailAdd"
                class="btn bg-blue px-4 py-2 rounded-pill text-white my-3"
              >
                Añadir
              </button>
              <div v-for="(email, m) in correos" :key="m">
                <div
                  class="alert alert-primary p-2"
                  style="width: max-content"
                  role="alert"
                >
                  {{ email }}
                  <button
                    class="btn p-0 m-0"
                    @click="borrarEquipoSeleccionado(k)"
                  >
                    <i class="bi bi-x ms-3"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="my-5">
              <div class="d-flex justify-content-center my-5">
                <div class="text-center d-block col-lg-3 col-sm-12">
                  <div class="w-100">
                    <button
                      type="button"
                      class="btn bg-blue rounded-pill text-white w-100"
                      @click="editar"
                    >
                      Guardar
                    </button>
                  </div>
                  <div class="w-100">
                    <button
                      type="button"
                      class="btn text-blue w-100"
                      @click="$router.back()"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import { useField, useForm } from "vee-validate";
import * as Yup from "yup";
import reportes from "@/services/informes.service";
import equipos from "@/services/equipos.service";
import swal from "sweetalert2";

export default defineComponent({
  name: "editar-informe",
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const informe = ref({});
    const traerInformacion = () => {
      reportes.verInformePorId(route.params.id).then((res) => {
        console.log("aquiiiiiii", res.data);
        informe.value = res.data;
        model.nombre = res.data.nombre;
        model.hora = res.data.hora;
        diasSeleccionados.value = res.data.dias.split(",").map((a) => +a);
        correos.value = res.data.correos;
        equiposSeleccionados.value = res.data.equipos;
      });
    };
    const sensores = ref([]);
    const traerSensores = () => {
      equipos.traerTodosLosDispositivos().then((res) => {
        console.log(res.data);
        sensores.value = res.data;
      });
    };
    const ubicacion = ref("");
    const equiposSeleccionados = ref([]);
    const equipoSeleccionado = ref({});
    const selectSensor = () => {
      const prueba = (valor) => valor === equipoSeleccionado.value;
      if (equiposSeleccionados.value.some(prueba) == false) {
        equiposSeleccionados.value.push(equipoSeleccionado.value);
        console.log(equiposSeleccionados);
      } else {
        console.log("Ya esta seleccionado");
      }
    };
    const borrarEquipoSeleccionado = (i) => {
      equiposSeleccionados.value.splice(i, 1);
      console.log("borrado", i);
    };
    const diasDeLaSemana = ref([
      {
        nombre: "L",
        id: 2,
      },
      {
        nombre: "M",
        id: 3,
      },
      {
        nombre: "W",
        id: 4,
      },
      {
        nombre: "J",
        id: 5,
      },
      {
        nombre: "V",
        id: 6,
      },
      {
        nombre: "S",
        id: 7,
      },
      {
        nombre: "D",
        id: 1,
      },
    ]);
    const diasSeleccionados = ref([]);
    const selectDias = (dia) => {
      if (diasSeleccionados.value.some((a) => a === dia)) {
        const indice = diasSeleccionados.value.findIndex((a) => a === dia);
        diasSeleccionados.value.splice(indice, 1);
        console.log("por el si", diasSeleccionados.value);
      } else {
        diasSeleccionados.value.push(dia);
        console.log("por el no", diasSeleccionados.value);
      }
    };
    const correos = ref([]);
    const email = ref("");
    const validateEmail = (a) => {
      return String(a)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const onEmailAdd = () => {
      if (email.value == "") return;
      if (!validateEmail(email.value)) {
        swal.fire("Error", "Ingrese un correo valido", "error");
        return;
      }
      correos.value.push(email.value);
      email.value = "";
    };
    const validationSchema = Yup.object().shape({
      nombre: Yup.string().required("Este campo es requerido"),
      hora: Yup.string().required("Este campo es requerido"),
    });
    const { handleSubmit, values: model } = useForm({
      validationSchema,
    });
    useField("nombre", null, { initialValue: "" });
    useField("hora", null, { initialValue: "" });

    const editar = handleSubmit((values) => {
      values.dias = diasSeleccionados.value.join(",");
      values.dispositivos = equiposSeleccionados.value.map((a) => a._id);
      values.correos = correos.value;
      reportes.editarInformes(route.params.id, values).then((res) => {
        console.log(res);
        swal
          .fire("Guardado", "Esta area se ah editado con exito", "success")
          .then((res) => {
            router.back();
            console.log(res);
          });
      });
    });
    onMounted(() => {
      traerSensores();
      setCurrentPageBreadcrumbs("Documents", ["Pages", "Profile"]);
      traerInformacion();
    });
    return {
      model,
      sensores,
      ubicacion,
      equiposSeleccionados,
      selectSensor,
      equipoSeleccionado,
      borrarEquipoSeleccionado,
      diasDeLaSemana,
      selectDias,
      diasSeleccionados,
      email,
      onEmailAdd,
      correos,
      informe,
      editar,
    };
  },
});
</script>
<style>
.fondoActivo {
  background-color: #27aae1 !important;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.fondoInactivo {
  background-color: #bbbbbb;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
